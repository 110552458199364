<template>
  <div>

    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn-toggle borderless>
          <v-btn @click="selectedCreditMode = 1">
            <v-img width="80px" :src="creditSafeLogo"> </v-img>
          </v-btn>
          <v-btn @click="selectedCreditMode = 2">
            <v-img width="80px" :src="experianLogo"> </v-img>
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <div v-if="selectedCreditMode === 1">
      <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
        <v-text-field v-model="form.creditSafeCompanyName" label="Credit Safe Company Name" prepend-icon="mdi-credit-card"
          required></v-text-field>
        <v-text-field ref="creditSafeScore" v-model="form.creditSafeScore" label="Credit Safe Score"
          prepend-icon="mdi-chart-arc" @keypress="NoLettersAccepted" :rules="creditScoreRules" required></v-text-field>
        <v-text-field v-model="form.creditSafeLimit" label="Credit Safe Limit" @keypress="NoLettersAccepted"
          prepend-icon="mdi-currency-gbp">
        </v-text-field>
        <v-text-field v-model="form.creditSafeID" prepend-icon="mdi-credit-card-check"
          label="Credit Safe ID"></v-text-field>
        <v-text-field :value="creditScoreDate" prepend-icon="mdi-credit-card-check"
          label="Credit Safe Score Date" disabled></v-text-field>
      </v-form>
      <!-- {{form}} -->
    </div>
    <div v-if="selectedCreditMode === 2">
      <v-form class="pa-5" ref="form2" v-model="valid" lazy-validation>
        <v-text-field v-model="form2.experianCompanyName" label="Experian Company Name" prepend-icon="mdi-credit-card"
          required></v-text-field>
        <v-text-field v-model="form2.experianScore" ref="experianScore" label="Experian Score"
          prepend-icon="mdi-chart-arc" @keypress="NoLettersAccepted" :rules="experianScoreRules" required></v-text-field>
        <v-text-field v-model="form2.experianLimit" label="Experian Limit" @keypress="NoLettersAccepted"
          prepend-icon="mdi-currency-gbp">
        </v-text-field>
        <v-text-field prepend-icon="mdi-credit-card-check" label="Experian Score Date"
          :value="experianScoreDate" disabled></v-text-field>
      </v-form>
      <!-- {{form2}} -->
    </div>
    <!-- {{form}} -->

    <div class="mt-4">
      <v-btn depressed color="accent" @click="validateAndUpdate" block dark large>
        Save changes
      </v-btn>
    </div>

  </div>
</template>

<script>
import { mergeExistingEntriesIntoForm, changesSaved, somethingWentWrong } from "@/helpers/utilities"
import { mapGetters, mapActions } from "vuex"
import api from "@/store/api"
// import { required } from "@/helpers/form-validations"
import dayjs from 'dayjs'

export default {
  data: () => ({
    creditSafeLogo: require("@/assets/credit-safe-logo.svg"),
    experianLogo: require("@/assets/experian-logo.svg"),
    valid: true,
    form: {
      creditSafeScore: 0,
      creditSafeLimit: 0,
      creditSafeID: 0,
      creditScoreDate: 0,
      creditSafeCompanyName: "",
      companyId: 0
    },
    form2: {
      experianScore: 0,
      experianLimit: 0,
      // experianID: 0,
      experianScoreDate: 0,
      experianCompanyName: "",
      companyId: 0
    },
    selectedCreditMode: null,
    creditScoreDate:dayjs().format('DD/MM/YYYY'),

    experianScoreDate:dayjs().format('DD/MM/YYYY'),
  }),
  watch: {
    formEditMode(value) {
      if (!value) {
        this.$refs.form.reset()
      } else {
        this.bindCompanyDetails()
      }
    },
  },
  computed: {
    ...mapGetters("company", ["details", "companyId"]),
    ...mapGetters("ui", ["formEditMode"]),
    creditScoreRules() {
      const min = 1
      const max = 100
      return [
        v => ((v >= min) && (v <= max)) || 'Credit Score must be between ' + min + ' and ' + max
      ]
    },
    experianScoreRules() {
      const min = 1
      const max = 1000
      return [
        v => ((v >= min) && (v <= max)) || 'Experian Score must be between ' + min + ' and ' + max
      ]
    },
    // creditSafeLimitRules(){

    // }
  },
  mounted() {
    this.fetchCompanyDetails()
    if (this.formEditMode) { 
      if(this.form2.experianScoreDate){
      
      this.experianScoreDate = this.$helpers.formatDate(this.form2.experianScoreDate) }
   }
   if(this.form.creditScoreDate){
    this.creditScoreDate = this.$helpers.formatDate(this.form.creditScoreDate) 
   
   }
   },
  methods: {
    ...mapActions("company", ["fetchCompanyDetails"]),
    ...mapActions("notes", ["fetchNotes"]),
    validateAndUpdate() {
      const creditScore = this.form.creditSafeScore
      if (creditScore === '' || creditScore === 0 || creditScore === '0' || creditScore >= 101) {
        const form = {
          creditSafeScore: this.form.creditSafeScore,

        }
        this.formHasErrors = false
        Object.keys(form).forEach(f => {
          if (this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }
      const experianScore = this.form2.experianScore
      if (experianScore === '' || experianScore === 0 || experianScore === '0' || experianScore >= 1001) {
        const form = {
          experianScore: this.form2.experianScore,

        }
        this.formHasErrors = false
        Object.keys(form).forEach(f => {
          if (this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        })
        return false
      }

      this.submitForm()
    },
    async submitForm() {
      let apiUrl = ''
      let creditDetails = {}
      if (this.selectedCreditMode === 1) {

        apiUrl = 'updateCreditScore'

        const sectionEdited = this.form.creditSafeCompanyName ? 'Credit Safe Company Name'
          : this.form.creditSafeScore ? 'Credit Safe Score'
            : this.form.creditSafeLimit ? 'Credit Safe Limit'
              : 'Credit Safe ID'
        const oldValue = this.form.creditSafeCompanyName ? this.details.creditSafeCompanyName
          : this.form.creditSafeScore ? this.details.creditSafeScore
            : this.form.creditSafeLimit ? this.details.creditSafeLimit
              : this.details.creditSafeLimit
        const newValue = this.form.creditSafeCompanyName ? this.form.creditSafeCompanyName
          : this.form.creditSafeScore ? this.form.creditSafeScore
            : this.form.creditSafeLimit ? this.form.creditSafeLimit
              : this.form.creditSafeLimit
        const noteMessage = `Edit Company Section | Company ${sectionEdited} from ${oldValue} to ${newValue}`
        creditDetails = {
          // companyId: this.form.companyId,
          companyId: this.$store.getters['company/companyId'],
          creditScore: this.form.creditSafeScore,
          limit: this.form.creditSafeLimit,
          currentUserId: this.$store.getters['employee/userId'],
          creditSafeCompanyName: this.form.creditSafeCompanyName,
          creditScoreDate: dayjs(this.creditScoreDate).unix(),
          noteMessage: noteMessage
        }
      } else {

        apiUrl = 'updateExperian'

        const sectionEdited = this.form2.experianCompanyName ? 'Experian Company Name'
          : this.form2.experianScore ? 'Experian Score'
            : this.form2.experianLimit ? 'Experian Limit'
              : 'Experian ID'
        const oldValue = this.form2.experianCompanyName ? this.details.experianCompanyName
          : this.form2.experianScore ? this.details.experianScore
            : this.form2.experianLimit ? this.details.experianLimit
              : this.details.experianLimit
        const newValue = this.form2.creditSafeCompanyName ? this.form2.creditSafeCompanyName
          : this.form2.experianScore ? this.form2.experianScore
            : this.form2.experianLimit ? this.form2.experianLimit
              : this.form2.experianLimit
        const noteMessage = `Edit Company Section | Company ${sectionEdited} from ${oldValue} to ${newValue}`
        creditDetails = {
          // companyId: this.form.companyId,
          companyId: this.$store.getters['company/companyId'],
          experianScore: this.form2.experianScore,
          experianLimit: this.form2.experianLimit,
          experianEmployeeID: this.$store.getters['employee/userId'],
          experianCompanyName: this.form2.experianCompanyName,
          experianScoreDate: dayjs(this.experianScoreDate).unix(),
          noteMessage: noteMessage
        }
      }

      try {
        const response = await api.post(`${apiUrl}`, creditDetails)
        if (response.status === 200) {
          changesSaved()
          setTimeout(() => {
            this.fetchCompanyDetails()
            this.fetchNotes()
          }, 1000)
        }
      } catch (error) {
        somethingWentWrong()
      }
    },
    NoLettersAccepted(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.returnValue = false
        return false
      } else {
        if (this.form.sortCode.length >= 6) {
          event.returnValue = false
          return false
        }
        event.returnValue = true
        return true
      }
    },
  },
  created() {
    if (this.formEditMode) {
      Object.entries(this.details).forEach(
        mergeExistingEntriesIntoForm(this.form)
      )
    }
    if (this.formEditMode) {
      Object.entries(this.details).forEach(
        mergeExistingEntriesIntoForm(this.form2)
      )
    }
  }
}
</script>
